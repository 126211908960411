import React from "react";

const Footer = () => {
  return (
    <div>
      <footer>
        <div>
          <a
            href="mailto:someone@example.com?Subject=Hello%20again"
            target="_top"
          >
            <h3>
              <i class="fa fa-copyright" aria-hidden="true"></i>{" "}
              lishugupta652@gmail.com.
            </h3>
            <div>
              Will catch you early.{" "}
              <i class="fa fa-simplybuilt" aria-hidden="true"></i>
            </div>
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
